import '../../Styles.css';
import {SiGithub, SiLinkedin} from 'react-icons/si';
import{FiMail} from 'react-icons/fi'

const Contact = () => {
    return (
        <div className='welcome contact'>
            <p><FiMail/>&emsp;emre19kaya@web.de</p>
            <SiGithub/>&emsp;<a target="_blank" rel="noopener noreferrer" href='https://github.com/emre19K'>GitHub</a>
            <br/>
            <SiLinkedin/>&emsp;<a target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/in/cemal-emre-kaya-35b424239/'>LinkedIn</a>
        </div>
    );
};

export default Contact;