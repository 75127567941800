import { useNavigate } from 'react-router-dom';
import './Navbar/Nav.css';

const Navbar = () => {

    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate('/');
    };

    const handleProjectsClick = () => {
        navigate('/projects');
    };

    const handleAboutClick = () => {
        navigate('/about');
    };

    const handleContactClick = () => {
        navigate('/contact');
    };

    const handleHallOfFameClick = () => {
        navigate('/halloffame');
    };

    return (
        <nav>
            <h1 style={{ color: 'white' }}>EMRE<span id='red'>19</span>K</h1>
            <ul>
                <li><button onClick={handleHallOfFameClick}><h1 className='buttonh1'>Hall of Fame</h1></button></li>
                <li><button onClick={handleHomeClick}><h1 className='buttonh1'>Home</h1></button></li>
                <li><button onClick={handleProjectsClick}><h1 className='buttonh1'>Projekte</h1></button></li>
                <li><button onClick={handleAboutClick}><h1 className='buttonh1'>Über mich</h1></button></li>
                <li><button onClick={handleContactClick}><h1 className='buttonh1'>Kontakt</h1></button></li>
            </ul>
        </nav>
    );
};

export default Navbar;