import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../Styles.css';

const initialValues = {
    name: "",
    nickname: ""
};


const Win = () => {

    const navigate = useNavigate();

    const [values, setValues] = useState(initialValues);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const bringMeToHallOfFame = {
        method: 'POST',
        headers: {
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": 'POST',
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            name: values.name,
            nickname: values.nickname
        })
    };


    const handleClick = async () => {
        await fetch('https://emre19kbackend.de/api', bringMeToHallOfFame);
        navigate('/halloffame');
    };


    return (
        <div className='hacking'>
            <h1>Herzlichen Glückwunsch!</h1>
            <p>Du hast gewonnen und darfst dich in die Hall of Fame Liste eintragen!</p>
            <label>
                Name:
                <input type="text" name="name" value={values.name} onChange={handleChange}></input>
            </label>

            <label>
                Nickname:
                <input type="text" name="nickname" value={values.nickname} onChange={handleChange}></input>
            </label>
            <button style={{ "backgroundColor": "white", "color": "black" }} onClick={handleClick}>Eintragen</button>
        </div>

    );
}

export default Win;