import { useNavigate } from "react-router-dom";
import { useState } from "react";

const initialValues = {
    password: ""
};

const Level1 = () => {

    const nav = useNavigate();

    const [values, setValues] = useState(initialValues);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleClick = () => {
        window.alert('Die URL ist komisch... Inspect?')
    };

    const handleSubmit = () => {
        console.log(values.password);
        if(values.password === 'emre19K_ist_cool123'){
            nav('/level2qwert');
        }else{
            window.alert('Leider falsch...')
        }
        setValues({password: ''});
    };

    return(
        <>
            <button onClick={handleClick}>TIPP</button>
            <div className="welcome level1">
                <p>Oh man... Ich habe mein Passwort vergessen... Bitte hilf mir!</p>
                <label>
                    Password:
                <input type="text" name="password" title='emre19K_ist_cool123' value={values.password} onChange={handleChange}></input>
                <button onClick={handleSubmit}>LogIn</button>
            </label>
            </div>
        </>
    );
};

export default Level1;