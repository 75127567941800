import { useState, useEffect } from "react";
import '../../Styles.css';

const HallOfFame = () => {

    const [obj, setObjs] = useState(null);

    useEffect(() => {
        fetch('https://emre19kbackend.de/api')
            .then(res => {
                return res.json();
            })
            .then(data => {
                console.log(data)
                setObjs(data);
            });
    }, []);

    

    return (
        <div className="hof">
            <div>
                {obj && obj.map(ob => (
                    <div key={ob.nickname} >
                        <p id='hof'><span>Name:</span> {ob.name} <span>Nickname:</span> {ob.nickname}</p>
                    </div>
                ))}
            </div>


        </div>
    );
};

export default HallOfFame;