import { useNavigate } from "react-router-dom";
import { useState } from "react";

const initialValues = {
    password: ""
};

const Level2 = () => {

    const nav = useNavigate();

    const [values, setValues] = useState(initialValues);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleClick = () => {
        window.alert('Was hat Julius Caesar damit zutun?')
    };

    const handleSubmit = () => {
        console.log(values.password);
        if(values.password === 'open' || values.password === 'OPEN'){
            nav('/level3tzui');
        }else{
            window.alert('Leider falsch...')
        }
        setValues({password: ''});
    };

    return(
        <>
            <button onClick={handleClick}>TIPP</button>
            <div className="welcome level1">
                <p>Ich muss durch diese Geheimtür! Neben dem Codelock ist ein Zettel. Auf diesem steht:</p>
                <p>PQFO -1</p>
                <label>
                    Password:
                <input type="text" name="password" value={values.password} onChange={handleChange}></input>
                <button onClick={handleSubmit}>LogIn</button>
            </label>
            </div>
        </>
    );
};

export default Level2;