import fullstackerz from '../../assets/Blog_Portfolio.png';
import leetcode from '../../assets/Leet.png';
import backend from '../../assets/Back.png';

const Projects = () => {
    return (
        <div className="welcome projects">
            <a target="_blank" rel="noopener noreferrer" href="https://fullstackerz.de"><img alt='Mein Blog' src={fullstackerz}/></a>
            <a  target="_blank" rel="noopener noreferrer" href="https://leetcode.com/emre19K/"><img alt='Mein Leetcode' src={leetcode}/></a>
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/emre19K/NodeJS_Projekt/tree/main"><img alt='Mein Forum Backend' src={backend}/></a>
        </div>
    );
};

export default Projects;