import '../../Styles.css';

const About = () => {
    return (
        <div className='about'>
            <div id='about-text'>
                <p>Hallo!</p>
                <p>Ich heiße Cemal-Emre Kaya und bin 22 Jahre jung.</p>
                <p>Aktuell bin ich an der BHT-Berlin als Medieninformatiker immatrikuliert mit der Anstrebung meinen Master zu machen.</p>
                <p>Seit meinem 14. Lebensjahr beschäftige ich mich freizeitlich mit der Software-Entwicklung.</p>
                <p>Es fing an mit einem kleinen Gameserver und nun arbeite ich hart daran ein Fullstack-Entwickler zu werden.</p>
                <p>Ich interessiere mich für jeden Teil der Software-Entwicklung, ganz großes Interesse habe ich jedoch in der Webentwicklung.</p>
                <p>Wie ein Server funktioniert, wie eine Webseite aufgebaut wird und wie diese zusammen das World-Wide-Web repräsentieren.</p>
                <p>Immerhin ist das WWW nicht mehr als eine riesen Verlinkung von einzelnen Projekten (in meinem Fall: React)</p><br/>
                <p>Mittlerweile bin ich durch leidenschaft und lernfreude an dem Punkt angekommen, andem ich:</p>
                <p>&emsp;- Backend-Server mit allen CRUD-Methoden und weiteren API´s</p>
                <p>&emsp;- React Webseiten mit Backend-Verbindung (fetching)</p>
                <p>&emsp;- Projekt CI/CD Integration</p>
                <p>aufbauen kann.</p>
                <br/>
                <hr/>
                <br/>
                <p>Mein Skillset:</p>
                <p>&emsp;&#183; Backend (9/10)</p>
                <p>&emsp;&emsp;&emsp;- NodeJS</p>
                <p>&emsp;&emsp;&emsp;- Express</p>
                <p>&emsp;&#183; Frontend (6/10)</p>
                <p>&emsp;&emsp;&emsp;- React</p>
                <p>&emsp;&emsp;&emsp;- HTML</p>
                <p>&emsp;&emsp;&emsp;- CSS (leider nicht so gut, wie man sieht...)</p>
                <p>&emsp;&emsp;&emsp;- Javascript</p>
                <p>&emsp;&#183; DevOps(5/10)</p>
                <p>&emsp;&#183; GIT(7/10)</p>
            </div>
        </div>
    );
};

export default About;