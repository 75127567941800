import '../Styles.css';

import { useNavigate } from 'react-router-dom';

const Welcome = () => {

    const nav = useNavigate();

    const handleClick = () => nav('/inspectmebaby');

    return (
        <>
            <div className='welcome'>
                <h2>Willkommen zu meinem Portfolio!</h2>
                <p>Komm lass uns ein Spiel spielen...</p>
                <p>Zeig mir was du drauf hast!</p>
                <button onClick={handleClick}>Los geht's</button>
            </div>
            
        </>

    );
};

export default Welcome;