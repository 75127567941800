import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import './Styles.css';

import Welcome from './pages/Welcome';
import Navbar from "./pages/Navbar";
import About from "./pages/Navbar/About";
import Projects from "./pages/Navbar/Projects";
import Contact from "./pages/Navbar/Contact";
import HallOfFame from "./pages/Navbar/HallOfFame";

import Level1 from "./pages/QuizGame/Level1";
import Level2 from "./pages/QuizGame/Level2";
import Level3 from "./pages/QuizGame/Level3";
import Win from "./pages/QuizGame/WIn";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route excact path="/halloffame" element={<HallOfFame/>} />
          <Route excact path="/" element={<Welcome/>} />
          <Route excact path="/projects" element={<Projects/>} />
          <Route excact path="/about" element={<About/>} />
          <Route excact path="/contact" element={<Contact/>} />

          <Route excact path="/inspectmebaby" element={<Level1/>} />
          <Route excact path="/level2qwert" element={<Level2/>}/>
          <Route excact path="/level3tzui" element={<Level3/>}/>
          <Route exact path="/win" element={<Win/>}/>
        </Routes>
      </Router>
    </>
    
  );
}

export default App;
